import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bonus-card',
  templateUrl: './bonus-card.component.html',
  styleUrls: ['./bonus-card.component.css']
})
export class BonusCardComponent implements OnInit {
  @Input() bonus;
  @Input() admin;
  @Output() saveCardEvent = new EventEmitter<string>();
  @Output() deleteCardEvent = new EventEmitter<string>();

  public editing: boolean = false;
  public editDescription:boolean;
  public descriptionValue:any = "";  
  public editTitle:boolean;
  public titleValue:any = "";
  public editLogoUrl:boolean;
  public logoUrlValue:any = "";
  public editBonusUrl:boolean;
  public bonusUrlValue:any = "";

  public descriptionLines: any = [];

  constructor() {
    this.editDescription = false;
  }

  ngOnInit(): void {
  }

  goToLink(link) {
    window.location.href = link;
  }

  editCard() {
    this.editing = !this.editing;
  }

  createLineBreak() {
    if (this.bonus.description) {
      return this.bonus.description.split("\n");
    }
  }

  // edit(area) {
  //   switch (area) {
  //     case 'description':
  //       this.editDescription = !this.editDescription;
  //       this.bonus.description = this.descriptionValue;
  //       break;
  //     case 'title':
  //       this.editTitle = !this.editTitle;
  //       this.bonus.title = this.titleValue;
  //       break;
  //     case 'logoUrl':
  //       this.editLogoUrl = !this.editLogoUrl;
  //       this.bonus.logoUrl = this.logoUrlValue;
  //       break;
  //     case 'bonusUrl':
  //       this.editBonusUrl = !this.editBonusUrl;
  //       this.bonus.bonusUrl = this.bonusUrlValue;
  //       break;
  //     case '':
  //       break;
  //   } 
  // }
  
  deleteCard() {
    this.deleteCardEvent.emit(this.bonus);
  }

  saveCard() {
    this.saveCardEvent.emit(this.bonus);
    this.editing = !this.editing;
  }
}
